require('../scss/common.scss');
import sizes from "../scss/_settings/_settings.scss";
import 'owl.carousel';

window.sizes = {};
Object.keys(sizes).map(function(key){
    window.sizes[key] = parseInt(sizes[key]);
});

window.Template = require('./lib/template.js');
window.Translit = require('./lib/translit.js');

require('./parts/common.js');
require('./parts/carousel.js');
require('./parts/banner-slider.js');

require('../scss/common.scss');
require('../fonts/Muller/face.css');


let opener = false;
$(document).on('click', '[data-header] svg, a.sizes-table', function () {
    $('[data-sizes-chart]').toggleClass('opened');
    opener = true;
    setTimeout(function () { opener = false; }, 100);
});
$(document).on('click', '[data-sizes-chart] *', function () {
    opener = true;
    setTimeout(function () { opener = false; }, 100);
});
$(document).on('click', '*', function () {
    setTimeout(function () {
        if (!opener) $('[data-sizes-chart]').removeClass('opened');
    }, 50);
});


$(window).trigger('resize');


// require('../components/ui-custom/jquery-ui.min.js');
// require('../components/ui-custom/jquery-ui.min.css');
// require('jquery');
// require('jquery-ui-bundle');


