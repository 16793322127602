window.is_mobile = false;
window.last_action = (+ new Date())/1000;
window.action_update = function (limit) {
    let ts = (+ new Date())/1000;
    if (ts - window.last_action > limit) {
        window.last_action = ts;
        return true;
    } else {
        return false;
    }
};


//window.onload = function() {
$(document).ready(function(){
    console.log('document ready');
    if(getCookie("acceptrules")!=="true"){
        $(".details-privacy").removeClass('hidden');
        console.log(document.cookie);
    }else{
        //alert( getCookie("acceptrules") )
        console.log(document.cookie);
    }
});

function getCookie(name) {
    var cookie = " " + document.cookie;
    var search = " " + name + "=";
    var setStr = null;
    var offset = 0;
    var end = 0;
    if (cookie.length > 0) {
        offset = cookie.indexOf(search);
        if (offset != -1) {
            offset += search.length;
            end = cookie.indexOf(";", offset)
            if (end == -1) {
                end = cookie.length;
            }
            setStr = unescape(cookie.substring(offset, end));
        }
    }
    return(setStr);
}


var old_is_mobile=null;
$(window).on('load resize', function () {
    console.log('on load resize');
    window.is_mobile = $(window).width() < sizes.M;
    console.log('is_mobile = ' + (window.is_mobile));
    if(old_is_mobile!=window.is_mobile){
        old_is_mobile = window.is_mobile;
        if(window.is_mobile){
            $('.showonmobile').removeClass('hidden')
            $('.hideonmobile').addClass('hidden')
        }else{
            $('.showonmobile').addClass('hidden')
            $('.hideonmobile').removeClass('hidden')
        }
    }
    $('.menu-items a.item[href="'+location.pathname+'"]').addClass('current');
    // let width = $('.product-cart-wrapper').first().width();
    // $('.product-cart-wrapper').width(width-5);
    // console.log('set product-cart-wrapper width  = ' + (width-5));
});

let ua = navigator.userAgent.toLowerCase();
window.is_safari = false;
if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
        window.is_chrome = true;
    } else {
        window.is_safari = true;
    }
}


// a.href linker
$.fn.link = function (e) {
    return $('[data-target="'+this.data('link')+'"]');
};

$(document).on('mouseover', '[data-link]', function () {
    $(this).link().addClass('hover');
});

$(document).on('mouseout', '[data-link]', function () {
    $(this).link().removeClass('hover');
});

$(document).on('click', '[data-link]', function () {
    if ($('body').hasClass('ready-for-edit')) return;
    let to = $(this).link().attr('href');
    if ($(this).link().attr('target') == '_blank') {
        if (to) window.open($(this).link().attr('href'), 'New')
    } else {
        if (to) location.href = $(this).link().attr('href');
    }
});

// Menu opener
$(document).on('click', '[data-mobile-opener]', function () {
    $('body').toggleClass('menu-opened');
});

// Search opener
$(document).on('click', '[data-mobile-search]', function () {
    $('body').toggleClass('search-opened');
});

// Footer opener
$(document).on('click touchend', '[data-class-toggle]', function () {
    if (window.action_update(0.5)) {
        $(this).toggleClass($(this).data('class-toggle'));
    }
});

// Filter item opener
$(document).on('click', '[data-toggle-parent]', function (e) {
    if (e.target.dataset['blocker']) return ;
    $(this).parent().toggleClass(this.dataset['toggleParent']);
});

// Catalog openers
$(document).on('click', '[data-open-catalog]', function () {
    $($(this).data('open-catalog')).toggleClass('opened');
});

// Tabs
$('[data-tab]').click(function () {
    console.log('data-tab click');
    let cnt = $(this).closest('[data-tab-container]');
    cnt.find('[data-tab]').removeClass('current');
    cnt.find('[data-tab-content]').removeClass('current');

    cnt.attr('data-selected', this.dataset['tab']);

    cnt.find('[data-tab="'+this.dataset['tab']+'"]').addClass('current');
    cnt.find('[data-tab-content="'+this.dataset['tab']+'"]').addClass('current');
});

// DropDowns
$(document).on('click', '.drop-down.opened [data-variant]', function () {
    console.log('dropdown remove opened 1');
    let box = $(this).closest('.drop-down');
    box.removeClass('opened');
    box.find('[data-current]').html($(this).text());
});

$(document).on('click', '.drop-down .name', function(ev){
    console.log('dropdown add/remove opened 2');
    let current = $(this).parent().parent();
    let opened = current.hasClass('opened');
    let openedOneOf = $('.drop-down').hasClass('opened');
    if(openedOneOf){
        // 2020-07-24 применяем этот фильтры
        $('[data-sync-button]').trigger("click");
        $('.drop-down').removeClass('opened');
    }
    if (!opened) current.addClass('opened');
});
$(document).on('click', '.drop-down .decorator', function(ev){
    console.log('dropdown add/remove opened 22');
    let current = $(this).parent().parent();
    let opened = current.hasClass('opened');
    let openedOneOf = $('.drop-down').hasClass('opened');
    if(openedOneOf){
        // 2020-07-24 применяем этот фильтры
        $('[data-sync-button]').trigger("click");
        $('.drop-down').removeClass('opened');
    }
    if($(this).html()!=='X'){
        if (!opened) current.addClass('opened');
    }
});

let closer_timer = false;
$(document).on('click', '*', function () {
    if (closer_timer) return;
    closer_timer = true;
    setTimeout(function () {
        closer_timer = false;
    }, 150);

    if ($(this).closest('.drop-down').length > 0) return ;
    console.log('dropdown remove opened 3');
    $('.drop-down.opened').removeClass('opened');
    // 2020-07-24 применяем фильтры
    $('[data-sync-button]').trigger("click");
});


$(window).on('load resize', function(){
    $('[data-image]').each(function(){
        let img = this.dataset.imageMobile ? this.dataset.imageMobile : this.dataset.image;
        if ($(window).width() >= sizes.XS) img = this.dataset.image;
        if (!window.is_safari) img = img.replace('.jpg', '.webp').replace('.png', '.webp');
        $(this).css('background-image', 'url("'+img+'")');
    });
});

// Product cart
let hover;
$(document).on('mouseover', '.product-cart', function () {
    let that = this;
    if ($(this).find('svg.hello').length == 0) $(this).prepend(Template('product-hello'));
    hover = setTimeout(function () {
        $(that).addClass('hello-action');
        setTimeout(function () {
            $(that).removeClass('hello-action');
        }, 4000);
    }, 4000);
});

$(document).on('mouseout', '.product-cart', function () {
    clearTimeout(hover);
    $('.hello-action').removeClass('hello-action');
});


function sharped (){
    if (location.hash.length < 2) return;
    let element = $('.' + location.hash.substr(1));
    if (!element.length) return;
    $('html, body').animate({
        scrollTop: element.offset().top - 60
    }, element.offset().top * 0.4);
}

setTimeout(sharped, 750);

$(document).on('touchstart', '.mobile-menu-content .links-column', function () {
    let scroll = $(this).attr('href').indexOf('#') != -1;

    setTimeout(function(){
        $('body').removeClass('menu-opened');
        if (scroll) sharped();
    }, 350);
});





