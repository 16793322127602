module.exports = (function(){
    return {
        'IN' : function (word) {
            let answer = "";
            let a = {
                "Ё":"YO","Й":"I","Ц":"TS","У":"U","К":"K","Е":"E","Н":"N","Г":"G","Ш":"SH","Щ":"SCH","З":"Z","Х":"H","Ъ":"'",
                "ё":"yo","й":"i","ц":"ts","у":"u","к":"k","е":"e","н":"n","г":"g","ш":"sh","щ":"sch","з":"z","х":"h","ъ":"'",
                "Ф":"F","Ы":"I","В":"B","А":"a","П":"P","Р":"R","О":"O","Л":"L","Д":"D","Ж":"ZH","Э":"E","ф":"f","ы":"i",
                "в":"b","а":"a","п":"p","р":"r","о":"o","л":"l","д":"d","ж":"zh","э":"e","Я":"Ya","Ч":"CH","С":"С","М":"M",
                "И":"I","Т":"T","Ь":"'","Б":"B","Ю":"YU","я":"ya","ч":"ch","с":"с","м":"m","и":"i","т":"t","ь":"'","б":"b",
                "ю":"yu"
            };
            for (let i = 0; i < word.length; i++) answer += a[word[i]] === undefined ? word[i] : a[word[i]];
            return answer;
        },
        'OUT' : function (word) {
            let answer = "";
            let a = {
                "Y":"Ю","I":"И","T":"Т","U":"У","K":"К","E":"Э","N":"Н","G":"Г","S":"С","Z":"Ж","H":"Х","'":"ь","y":"ю",
                "i":"и","t":"т","u":"у","k":"к","e":"э","n":"н","g":"г","s":"с","z":"ж","h":"х","F":"Ф","V":"В","a":"а",
                "P":"П","R":"Р","O":"О","L":"Л","D":"Д","f":"ф","v":"в","p":"п","r":"р","o":"о","l":"л","d":"д","C":"Ч",
                "M":"М","B":"Б","c":"ч","m":"м","b":"б"
            };
            for (let i = 0; i < word.length; i++) answer += a[word[i]] === undefined ? word[i] : a[word[i]];
            return answer;
        },
    };
})();
