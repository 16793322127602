// Arrow events
$(document).on('click', '[data-trigger]', function () {
    let owl = $(this).closest('[data-carousel-wrapper]').find('[data-carousel]');
    if (owl) owl.trigger(this.dataset['trigger']);

    let that = $(this);
    that.addClass('animation-go');
    setTimeout(function () {
        that.removeClass('animation-go');
    }, 200);
});

// Default carousel - баннеры на главном экране
$('[data-carousel="default"]').each(function () {
    let one = $(this).find('.item').length <= 1;
    if (one) $(this).parent().find('.arrow-button').hide();
    let needLoop=true;
    if ($(this).find('.item').length <= 1) {
        needLoop = false;
    }

    let options = {
        //loop: one ? false : true,
        loop: needLoop,
        dots: $(this).data('dots') || false,
        items: $(this).data('items') || 3,
        margin: 0,
        smartSpeed: 1000,
        autoplayHoverPause: false,
        lazyLoad: true,
        // animateOut: 'slideOutDown',
        // animateIn: 'flipInX',
    };

    if ($(this).data('autoplay')) {
        options['autoplay'] = true;
        options['autoplayTimeout'] = parseInt($(this).data('autoplay'));
        options['autoplayHoverPause'] = true;
    }

    $(this).owlCarousel(options);
});

// Custom carousels
let resp;

resp = {0: {items: 1}};
resp[sizes.XS] = {items: 3};
$('[data-carousel="home-banner"]').owlCarousel({
    dots: true,
    loop: true,
    margin: 0,
    responsive: resp,
    autoplay: 5000
});

resp = {0: {loop: true}};
resp[sizes.XS] = {items: 2,loop: true};
resp[sizes.M] = {items: 2,loop: true};
$('[data-carousel="collections"]').owlCarousel({
    dots: false,
    loop: false,
    items: 3,
    margin: 0,
    responsive: resp
});

resp = {0: {items: 2,loop: false,singleItem:true,}};
resp[710] = {items: 3,loop: false};
$('[data-carousel="collections2"]').owlCarousel({
    dots: false,
    margin: 0,
    //autoWidth:true,
    navigation: true,
    responsive: resp,
    onDragged: callback,
    onChanged: callback,
    onInitialized: callback,
    mouseDrag: false,
    touchDrag:false,
    pullDrag:false,
});
function callback(event){
    $('.owl-stage').css('width','100vw');
    if($( window ).width()<710){
        $('.provider-item').css('width','92vw');
    }else if($( window ).width()<1200){
        $('.provider-item').css('width','auto');
    }else{
        $('.provider-item').css('width','auto');
    }
    var item = event.item.index;
    //$('.owl-item.active').removeClass('active');
    $('.owl-item').addClass('active');
    console.log('Current Slide: '+ item);
    console.log('event : ',event.relatedTarget['_drag']['direction'])
    if(event.relatedTarget['_drag']['direction']==='right'){
        //$('.owl-carousel').trigger("to.owl.carousel", [0, 1,true]);
        $('.owl-carousel').trigger('prev.owl.carousel');
        console.log('set prev');
    }
    if(event.relatedTarget['_drag']['direction']==='left'){
        //$('.owl-carousel').trigger("to.owl.carousel", [1, 1,true]);
        $('.owl-carousel').trigger('next.owl.carousel');
        console.log('set next');
    }
}


resp = {0: {items: 3,loop: false}};
resp[sizes.XS] = {items: 3,loop: false};
resp[sizes.M] = {items: 3,loop: false};
$('[data-carousel="collections1"]').owlCarousel({
    dots: false,
    loop: false,
    items: 3,
    margin: 0,
    responsive: resp,
    mouseDrag: false,
    touchDrag:false,
    pullDrag:false,
});


resp = {0: {items: 2}};
resp[sizes.XS] = {items: 3};
resp[sizes.M] = {items: 4};
resp[sizes.L] = {items: 5};
resp[sizes.XL] = {items: 5};
$('[data-carousel="popular"]').owlCarousel({
    dots: false,
    loop: true,
    responsive: resp,
    autoplay: 5000,
    //autoWidth:true,
    center:true,
    margin:20,
});

window.touch_ = false;
$(document).on('touchstart', function(e) {
    window.touch_ = { x: e.pageX, y: e.pageY };
});

$(document).on('touchend', function() {
    window.touch_ = false;
});

document.body.addEventListener('touchmove', function (e) {
    let dx = Math.abs(window.touch_.x - e.pageX);
    let dy = Math.abs(window.touch_.y - e.pageY);
    if (dx > dy) e.preventDefault();
}, { passive: false });


//$(".intro-box").hide();
window.onload = function() {
    //$(".intro-box").hide().slideDown();
    $(".intro-box").css({"height":"auto"});
    //$(".intro-box").css("height","");
};
